<script setup lang="ts">
const product = defineProps([
	'title',
	'type',
	'imageUrl',
	'brand',
	'price',
	'label',
	'slug',
	'subtype',
	'offers',
	'productId'
])

let printifyOffers: any[] = [];
if (product.offers) {
	printifyOffers = product.offers.filter((item:any) => item.productId == product.productId)
}

let printifyOffer : any = null;
if (printifyOffers.length) {
	printifyOffer = printifyOffers[0]
}

const link = `/shop/${product.slug}`
const country = useState('country')

const getPrice = function() {
    if (country.value == "RO") {
        return `${(printifyOffer.price * 4.97 / 100).toFixed(0)} RON`
    }
    return `€${(printifyOffer.price / 100).toFixed(2)}`
}

const hasDiscountPrice = function() {
    return !!printifyOffer.discountPrice
}

const getDiscountPrice = function() {
    if (!hasDiscountPrice()) {
        return 0
    }

    if (country.value == "RO") {
        return `${(printifyOffer.discountPrice * 4.97 / 100).toFixed(0)} RON`
    }
    return `€${(printifyOffer.discountPrice / 100).toFixed(2)}`
}
</script>

<template>
<div class="pb-5">
	<h3>
        <nuxt-link :to="link" class="block max-h-7 overflow-hidden text-white font-bold text-lg uppercase">{{product.title}}</nuxt-link>
    </h3>
	<h4 class="opacity-50 text-white">{{product.subtype}} {{product.type}}</h4>
	<nuxt-link :to="link" class="block py-2 relative">
        <div class="bg-yellow-500 text-black p-1 text-center text-sm text-black absolute w-full" v-if="hasDiscountPrice()">DISCOUNT</div>
		<nuxt-img :alt="product.title" class="w-full h-auto" :src="product.imageUrl" />
		<div class="bg-neurodarkgrey p-1 rounded text-sm text-white absolute top-5 right-3" v-if="product.label">{{product.label.toUpperCase()}}</div>
		<div class="bg-red-900 p-1 rounded text-sm text-white absolute top-5 left-3" v-if="printifyOffer.stock < 8 && printifyOffer.stock > 0">ONLY A FEW LEFT</div>
		<div class="bg-red-900 p-1 rounded text-sm text-white absolute top-5 left-3" v-if="printifyOffer.stock == 0">OUT OF STOCK</div>

	</nuxt-link>
	<div class="flex flex-row">
		<div v-if="product.type == 'T-Shirt'">
			<p class="opacity-50 text-white">Brand</p>
			<h5 class="text-white">{{product.brand}}</h5>
		</div>
		<div v-if="product.type == 'T Shirt' && (!product.subtype || !product.subtype.includes('Bundle'))">
			<p class="opacity-50 text-white">Brand</p>
			<h5 class="text-white">{{product.brand}}</h5>
		</div>
		<div v-if="product.type == 'Cassette'">
			<p class="opacity-50 text-white">Artist</p>
			<h5 class="text-white">{{product.brand}}</h5>
		</div>
		<div v-if="product.subtype && product.subtype.includes('Bundle')">
			<p class="opacity-50 text-white">Artist</p>
			<h5 class="text-white">{{product.brand}}</h5>
		</div>
		<div v-if="product.type == 'CD'">
			<p class="opacity-50 text-white">Artist</p>
			<h5 class="text-white">{{product.brand}}</h5>
		</div>
		<div v-if="printifyOffers.length && !hasDiscountPrice()" class="ml-auto my-auto text-2xl text-white font-bold">
			{{getPrice()}}
		</div>
        <div v-if="printifyOffers.length && hasDiscountPrice()" class="ml-auto">
            <span class="my-auto px-1 text-white opacity-50 line-through">{{getPrice()}}</span>
            <span class="my-auto text-2xl text-white font-bold">{{getDiscountPrice()}}</span>
        </div>
	</div>
</div>
</template>
